<template>
  <template v-if="isGroup">
    <GroupCell :params="props.params" />
  </template>
  <div v-else class="elv-financials-cell-date-time-main">
    <el-tooltip
      :show-arrow="false"
      effect="dark"
      placement="top"
      popper-class="elv-report-table-tips"
      overlay-classname="elv-report-table-tips"
      :show-after="500"
    >
      <div class="elv-financials-cell-date-time-main-top">
        {{
          dayjs(props.params.data?.datetime)
            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
            .format(props.params.format || 'YYYY/MM/DD HH:mm')
        }}
      </div>
      <template #content>
        <div>
          <p>
            {{ t('project.originalDatetime')
            }}{{ calculateUtcOffset(props.params.data?.datetime, props.params.data?.source?.timezone ?? 'UTC') }}:
            {{
              dayjs(props.params.data?.datetime)
                .tz(props.params.data?.source?.timezone ?? 'UTC')
                .format('YYYY/MM/DD HH:mm')
            }}
          </p>
          <p>
            {{ t('project.utcDatetime') }}:
            {{ dayjs.tz(props.params.data?.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
          </p>
          <p>
            {{ t('project.reportingDatetime')
            }}{{ calculateUtcOffset(props.params.data?.datetime, entityStore.entityDetail?.timezone ?? 'UTC') }}:
            {{
              dayjs(props.params.data?.datetime)
                .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                .format('YYYY/MM/DD HH:mm')
            }}
          </p>
        </div>
      </template>
    </el-tooltip>

    <div v-if="bottomContent" class="elv-financials-cell-date-time-main-bottom">
      <div
        :class="[
          'elv-financials-cell-date-time-main-bottom__content',
          `elv-financials-cell-date-time-main-bottom__${props.bottomFields}`
        ]"
        :data-clipboard-text="bottomContent"
      >
        {{ formatInputAddress(bottomContent) }}
      </div>
      <img
        src="@/assets/img/copy.png"
        alt=""
        class="elv-financials-cell-date-time-main-bottom__copy"
        @click.stop="onClickedCopy(bottomContent)"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import GroupCell from './GroupCell.vue'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'
import { formatInputAddress, calculateUtcOffset } from '@/lib/utils'

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const entityStore = useEntityStore()
const { toClipboard } = useClipboard()

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  },
  bottomFields: {
    type: String,
    default: 'txHash'
  }
})

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const bottomContent = computed(() => {
  if (!props.params.data) return ''
  const keys = Object.keys(props.params.data)
  if (keys.indexOf(props.bottomFields) !== -1) {
    return props.params.data[props.bottomFields]
  }
  return ''
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}
</script>
<style lang="scss">
.elv-financials-cell-date-time-main {
  .elv-financials-cell-date-time-main-top {
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
  }

  .elv-financials-cell-date-time-main-bottom {
    display: flex;
    align-items: center;
    cursor: pointer;

    .elv-financials-cell-date-time-main-bottom__content {
      height: 16px;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $elv-color-838d95;
    }

    .elv-financials-cell-date-time-main-bottom__txHash {
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
    }

    .elv-financials-cell-date-time-main-bottom__copy {
      padding-left: 4px;
      width: 10px;
      height: 10px;
    }
  }
}
</style>
